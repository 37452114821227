<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div class="success-container p-6 text-center rounded">
          <FunctionalCalendar
            v-model="calendarData"
            :date-format="'dd/mm/yyyy'"
            :is-date-range="false"
            :is-date-picker="true"
            :isAutoCloseable="false"
            :changeMonthFunction="true"
            :changeYearFunction="true"
            :disabledDates="deseable"
          ></FunctionalCalendar>
          {{ sendValue }}
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import { FunctionalCalendar } from 'vue-functional-calendar'

export default {
  name: 'SuccessImageValidate',
  components: { PopupBase, FunctionalCalendar },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    },
    deseable: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    }
  },
  data () {
    return {
      calendarData: {},
      temoin: undefined
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.calendarData.selectedDate !== this.temoin && this.calendarData.selectedDate !== false) {
        this.$emit('oga', this.calendarData.selectedDate)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.temoin = this.calendarData.selectedDate
      }
    }
  },

  methods: {
    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', 'Cliquez ici pour sélectionner')
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 55%;
    }
    .success-container {
        //background-color: $white;
        z-index: 999;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .success-container{
        padding: 0px !important;
      }
      .cardinal{
        width: 55%;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .success-container{
        padding: 0px !important;
      }
      .cardinal{
        width: 80%;
      }
    }

    @media screen and (max-width: 700px) { /**androidddddddddddddddddddddddddddddddddddddddddddddddd */
      .success-container{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background-color: transparent;
        padding: 0px !important;
        border-radius: 6px 6px 6px 6px;
      }
      .cardinal{
        width: 100%;
      }
      .iconpp{
        width: 100%;
        height: 70px
      }
      .button{
        font-size: 0.9em
      }
      .role{
        font-size: 0.9em
      }
    }

</style>
