<template>
  <div>
    <popup-date :want-to-make-offer="activeDate" :deseable="deseable" @oga="retourDate" class="pop"/>
    <div class="input p-1 pl-4 pr-4 flex items-center cursor-pointer" :class="{'error': error === true, 'succe': error === false}">
      <svg-icon
        name="calendrier"
        class="h-6 mr-3"
        original
        @click="openDate"
      />
      <input v-model="calendarData.selectedDate" class="w-full" placeholder="02/01/2022"/>
    </div>
    {{sendValue}}
  </div>
</template>

<script>
import popupDate from '@/components/popup/popupDate'
// import { Datetime } from 'vue-datetime'
// import 'vue-datetime/dist/vue-datetime.css'

export default {
  name: 'Index',
  components: {
    // Datetime,
    popupDate
  },
  props: {
    deseable: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    value: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      dure: '',
      calendarData: {
        selectedDate: ''
      },
      activeDate: false,
      error: true
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      // console.log(this.deseable.indexOf('afterToday'))
      if (this.calendarData.selectedDate.length === 10) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dure = this.calendarData.selectedDate.substr(3, 3) + this.calendarData.selectedDate.substr(0, 3) + this.calendarData.selectedDate.substr(6, 4)
        // console.log(this.dure)
        const event = new Date(this.dure)
        if (this.dateLimit(event) === 1) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.error = false
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          const dure = event.toISOString()
          // console.log(this.conversion(this.dure))
          this.$emit('info', dure)
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.error = true
        }
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.error = true
      }
    }
  },

  mounted () {
    if (this.value !== null) {
      this.calendarData.selectedDate = this.value
    }
  },

  methods: {
    openDate () {
      this.activeDate = true
    },

    dateLimit (date) {
      if (this.deseable.indexOf('afterToday') !== -1) {
        const now = new Date().getTime()
        const temps = parseInt(date.getTime()) - now
        let jours = temps / (1000 * 3600 * 24)
        jours = jours.toFixed(0)

        if (jours >= 1) {
          return 0
        } else {
          return 1
        }
      } else if (this.deseable.indexOf('beforeToday') !== -1) {
        const now = new Date().getTime()
        const temps = parseInt(date.getTime()) - now
        const jours = temps / (1000 * 3600 * 24)

        if (jours >= 0) {
          return 1
        } else {
          return 0
        }
      }
    },

    retourDate (answer) {
      this.activeDate = false
      this.calendarData.selectedDate = answer
      if (this.calendarData.selectedDate) {
        let start = this.calendarData.selectedDate

        if (this.calendarData.selectedDate[1] === '/') {
          start = '0' + this.calendarData.selectedDate
        }
        if (start[4] === '/') {
          start = start.substr(0, 3) + '0' + start.substr(3, 6)
        }

        this.calendarData.selectedDate = start.substr(0, 3) + start.substr(3, 3) + start.substr(6, 4)
      }
    },

    conversion (value) {
      const date = new Date(value)
      return date.toLocaleDateString('en-gb')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.pop{
  z-index: 999;
}
.datePick /deep/ {
  width: 100%;

  .vdatetime-input{
    width: 100%;
    height: 100%;
    background-color: #fff;
    font-size: 18px;
  }
}

.input{
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
  font-family: $font-default;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #0e0e0e;
}
.input:focus-within {
  border: 1px solid $focus-color;
}
.input:hover {
  border: 1px solid $hover-color;
}
.error{
  border: 1px solid #ea9f9f;
  input{
    color: #ea3636;
  }
}
.succe{
  border: 1px solid #5fc187;
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .input{
    height: 65px;
    font-size: 18px;
  }
}

@media screen and (max-width: 700px){
  .input{
    height: 55px;
    font-size: 14px;
  }
}

</style>
